import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import useHorizontal from "@oberon-amsterdam/horizontal/hook";
import useWindowSize from "../hooks/useWindowSize";
import img from "../images/gabor_tamas_portrait_mobile.jpg"

//GRAPH QUERY
function IndexPage() {
  const data = useStaticQuery(graphql`
    query IndexImages {
      A: allCloudinaryMedia(filter: { tags: { in: "cover", eq: "ingatlan" } }) {
        edges {
          node {
            secure_url
            tags
          }
        }
      }
      B: allCloudinaryMedia(filter: { tags: { in: "cover", eq: "enterior" } }) {
        edges {
          node {
            secure_url
            tags
          }
        }
      }
      C: allCloudinaryMedia(filter: { tags: { in: "cover", eq: "epulet" } }) {
        edges {
          node {
            secure_url
            tags
          }
        }
      }
      D: allCloudinaryMedia(filter: { tags: { in: "cover", eq: "varoskep" } }) {
        edges {
          node {
            secure_url
            tags
          }
        }
      }
    }
  `);

  const clImagesA = data.A.edges;
  const clImagesB = data.B.edges;
  const clImagesC = data.C.edges;
  const clImagesD = data.D.edges;

  const [container, setContainer] = useState();
  useHorizontal({ container: container });

  const { width } = useWindowSize();
  const breakpoint = 1024;

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      {width > breakpoint ? (
        <div
          ref={(ref) => {
            setContainer(ref);
          }}
          className="hidden p-0 m-0 md:flex"
          style={{ height: `calc(100vh - 192px)` }}
        >
          <div className="flex items-center pl-8 pr-10 sm:pl-10 md:pl-32 lg:pr-24 min-w-3/5 lg:min-w-7/12 xl:min-w-6/12 3xl:min-w-5/12">
            <div className="max-w-md xl:max-w-2xl 3xl:max-w-4xl">
              <h1 className="mb-2 text-2xl font-black leading-snug text-black md:mb-6 lg:mb-10 xl:mb-6 2xl:mb-10 md:text-5xl lg:text-5xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl">
              Épület, enteriőr, ingatlan, városkép fotók készítése.
              </h1>
              <p className="max-w-3xl text-sm font-normal leading-6 text-black md:leading-9 md:text-xl xl:leading-8 lg:leading-8 lg:text-xl 2xl:leading-10 xl:text-lg 2xl:text-xl">
                Tamás vagyok, Budapest székhelyű fotográfus. Tevékenyégi köröm
                az épületek, belső terek, ingatlanok és építési projektek
                igényhez igazított fotózása, portfólió, website tartalom, marketing, hirdetés és publikációs célra.
              </p>
            </div>
          </div>

          {/* Ingatlan */}

          {clImagesA.map((image, index) => {
            return (
              <div
                style={{
                  marginRight: `5vh`,
                  minWidth: `fit-content`,
                }}
                className="relative image-item last:pr-12"
                key={`${index}-cl`}
              >
                <a href="/ingatlanok">
                  <div style={{willChange: `opacity`}} className="absolute top-0 left-0 flex items-center w-full h-full align-middle transition-colors duration-300 ease-in-out delay-150 bg-gray-900 bg-opacity-0 group hover:bg-opacity-20">
                    <h2 className="flex-initial mx-auto text-4xl font-extrabold leading-tight tracking-wide text-center text-white transition-opacity duration-300 ease-in-out delay-150 opacity-0 2xl:text-5xl 3xl:text-6xl group-hover:opacity-100">
                      Ingatlanok
                    </h2>
                  </div>
                    <img
                      loading="lazy"
                      style={{
                        height: `calc(100vh - 192px)`,
                      }}
                      key={image.id}
                      className="object-cover"
                      src={image.node.secure_url}
                      id="start"
                      alt={image.node.tags}
                    />
                </a>
              </div>
            );
          })}

          {/* Enteriőrök */}

          {clImagesB.map((image, index) => {
            return (
              <div
              style={{
                marginRight: `5vh`,
                minWidth: `fit-content`,
              }}
              className="relative image-item last:pr-12"
              key={`${index}-cl`}
            >
              <a href="/enteriorok">
                <div style={{willChange: `opacity`}} className="absolute top-0 left-0 flex items-center w-full h-full align-middle transition-colors duration-300 ease-in-out delay-150 bg-gray-900 bg-opacity-0 group hover:bg-opacity-20">
                  <h2 className="flex-initial mx-auto text-4xl font-extrabold leading-tight tracking-wide text-center text-white transition-opacity duration-300 ease-in-out delay-150 opacity-0 2xl:text-5xl 3xl:text-6xl group-hover:opacity-100">
                    Enteriőrök
                  </h2>
                </div>
                  <img
                    loading="lazy"
                    style={{
                      height: `calc(100vh - 192px)`,
                    }}
                    key={image.id}
                    className="object-cover"
                    src={image.node.secure_url}
                    id="start"
                    alt={image.node.tags}
                  />
              </a>
            </div>
            );
          })}

          {/* Épület */}

          {clImagesC.map((image, index) => {
            return (
              <div
              style={{
                marginRight: `5vh`,
                minWidth: `fit-content`,
              }}
              className="relative image-item last:pr-12"
              key={`${index}-cl`}
            >
              <a href="/epuletek">
                <div style={{willChange: `opacity`}} className="absolute top-0 left-0 flex items-center w-full h-full align-middle transition-colors duration-300 ease-in-out delay-150 bg-gray-900 bg-opacity-0 group hover:bg-opacity-20">
                  <h2 className="flex-initial mx-auto text-4xl font-extrabold leading-tight tracking-wide text-center text-white transition-opacity duration-300 ease-in-out delay-150 opacity-0 2xl:text-5xl 3xl:text-6xl group-hover:opacity-100">
                    Épületek
                  </h2>
                </div>
                  <img
                    loading="lazy"
                    style={{
                      height: `calc(100vh - 192px)`,
                    }}
                    key={image.id}
                    className="object-cover"
                    src={image.node.secure_url}
                    id="start"
                    alt={image.node.tags}
                  />
              </a>
            </div>
            );
          })}

          {/* Városkép */}

          {clImagesD.map((image, index) => {
            return (
              <div
              style={{
                marginRight: `5vh`,
                minWidth: `fit-content`,
              }}
              className="relative image-item last:pr-12"
              key={`${index}-cl`}
            >
              <a href="/varoskep">
                <div style={{width: `calc(100% - 48px)`, willChange: `opacity`}} className="absolute top-0 left-0 flex items-center h-full align-middle transition-colors duration-300 ease-in-out delay-150 bg-gray-900 bg-opacity-0 group hover:bg-opacity-20">
                  <h2 className="flex-initial pr-12 mx-auto text-4xl font-extrabold leading-tight tracking-wide text-center text-white transition-opacity duration-300 ease-in-out delay-150 opacity-0 2xl:text-5xl 3xl:text-6xl group-hover:opacity-100">
                    Városkép
                  </h2>
                </div>
                  <img
                    loading="lazy"
                    style={{
                      height: `calc(100vh - 192px)`,
                    }}
                    key={image.id}
                    className="object-cover"
                    src={image.node.secure_url}
                    id="start"
                    alt={image.node.tags}
                  />
              </a>
            </div>
            );
          })}
        </div>
      ) : (
        <div className="mb-6">
          <div className="flex-1 mx-4 items-center mt-12 mb-4 sm\:mx-12">
            <div className="max-w-xl">
              <h1 className="mb-4 text-4xl antialiased font-black leading-normal text-black md:mb-6 md:text-6xl">
              Épület, enteriőr, ingatlan, városkép fotók készítése.
              </h1>
              <div className="flex items-center mb-5">
                <img className="object-cover w-20 h-20 mr-4 border-4 border-white rounded-full shadow-lg" src={img} alt="Profile image"/>
                <button className="px-6 py-3 text-base font-semibold leading-none text-gray-900 transition duration-200 ease-in-out bg-white border border-gray-300 rounded-md shadow-md 2xl:font-medium 2xl:text-xl focus:outline-none hover:bg-blue-100 lg:mt-0">
                  Elérhetőségek
                </button>

              </div>
              <p className="max-w-3xl mb-12 text-lg antialiased leading-8 text-black md:leading-7 lg:text-base 2xl:leading-10 xl:text-lg 2xl:text-xl">
                Tamás vagyok, budapesti székhelyű fotográfus. Tevékenyégi köröm
                az épített környezet, épületek, ingatlanok és belső terek,
                igényhez igazított képi dokumentálása, website, portfólió,
                marketing, publikációs célra. Ha professzionális képekre van
                szüksége, keressen bizalommal.
              </p>
            </div>
          </div>
          <div>
            {clImagesA.map((image, index) => {
              return (
                <div
                  className="w-full mb-10 image-item last:mb-0 object-fit"
                  key={`${index}-cl`}
                >
                  <a href="/ingatlanok">
                    <img
                      loading="lazy"
                      key={image.id}
                      src={image.node.secure_url}
                      id="start"
                      alt={image.node.tags}
                    />
                    <div className="flex items-center w-full h-24 text-gray-800 align-middle bg-gray-200 ">
                      <h2 className="flex-initial ml-10 text-3xl font-bold leading-tight text-center">
                        Ingatlanok
                      </h2>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </Layout>
  );
}

export default IndexPage;
